import * as React from "react";

import Hero from "../components/hero";
import Layout from "../components/layout";
import Gallery from "../components/gallery";

import "../styles/global.scss";

const GalleryPage = () => {
  return (
    <Layout title="Gallery">
      <Hero
        title="Gallery"
        text="Explore the luxury and craftsmanship of The Edward."
        page="gallery"
      />
      <Gallery room="kennedy" />
    </Layout>
  );
};

export default GalleryPage;
