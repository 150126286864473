import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

import "./style.scss";

const Gallery = () => {
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "gallery" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                gatsbyImageData(width: 1400)
              }
            }
          }
        }
      }
    `
  );

  return (
    <SimpleReactLightbox>
      <div className="container">
        <div className="gallery">
          <section className="section">
            <div className="gallery__images">
              <SRLWrapper>
                {allFile.edges.map((images) => (
                  <GatsbyImage image={getImage(images.node)} />
                ))}
              </SRLWrapper>
            </div>
          </section>
        </div>
      </div>
    </SimpleReactLightbox>
  );
};

export default Gallery;
